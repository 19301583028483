@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 255, 255, 255;
  --background-end-rgb: 255, 255, 255;
  --sen-blue: #2B2E4A;
  --sen-red: #e44650;
}

@font-face {
  font-family: FamiljenGbi;
  src: url("../src/constants/fonts/FamiljenGroteskGF-BoldItalic.ttf");
}

@font-face {
  font-family:  ExpletusSans-BoldItalic;
  src: url("./constants/fonts/ExpletusSans-BoldItalic.ttf");
}

@font-face {
  font-family:  EncodeSansSemiCondensed-ExtraLight;
  src: url("./constants/fonts/EncodeSansSemiCondensed-ExtraLight.ttf");
}

@font-face {
  font-family:  Inter-SemiBold;
  src: url("./constants/fonts/Inter-SemiBold.ttf");
}

@font-face {
  font-family:  Montserrat;
  src: url("./constants/fonts/Montserrat-VariableFont_wght.ttf");
}

@font-face {
  font-family:  OpenSans-LightItalic;
  src: url("./constants/fonts/OpenSans-LightItalic.ttf");
}

.poppins-thin {
  font-family: "Poppins", sans-serif;
  font-weight: 100;
  font-style: normal;
}

.poppins-regular {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.poppins-medium {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-style: normal;
}

.poppins-large {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-style: normal;
}

body {
  margin: 0;
  font-family: "Poppins",-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.sen-input:focus {
  outline: 1px solid var(--sen-red);
  border: 1px solid var(--sen-red);
}

.sen-card {
  border-radius: 20px;
  margin: 60px 0;
  /*border: none;*/
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.1), 0 6px 30px 0 rgba(0, 0, 0, 0.19);
}

.slider-container {
  width: 90rem;
  height: 22rem;
  line-height: 0;
}

swiper-slide {
  width: 10rem;
  height: 4rem;
}

.service-card {
  background-color: #E6E6E6;
}

/*swiper-slide {*/
/*  background-color: rgb(4 47 46);*/
/*  color: #fff;*/
/*  font-size: 25px;*/
/*}*/

/*swiper-slide:nth-child(2) {*/
/*  background-color: rgb(15 118 110);*/
/*}*/

/*swiper-slide:nth-child(3) {*/
/*  background-color: rgb(45 212 191);*/
/*}*/

/*swiper-slide:nth-child(4) {*/
/*  background-color: rgb(153 246 228);*/
/*}*/

/*swiper-slide:nth-child(5) {*/
/*  background-color: rgb(34 197 94);*/
/*}*/

/*input[type=date]:required:invalid::-webkit-datetime-edit {*/
/*  color: transparent;*/
/*}*/
/*input[type=date]:focus::-webkit-datetime-edit {*/
/*  color: black !important;*/
/*}*/


/*input[value=""]::-webkit-datetime-edit{ color: transparent; }*/
/*input:focus::-webkit-datetime-edit{ color: #000; }*/

/*::-webkit-datetime-edit-year-field:not([aria-valuenow]),*/
/*::-webkit-datetime-edit-month-field:not([aria-valuenow]),*/
/*::-webkit-datetime-edit-day-field:not([aria-valuenow]) {*/
/*  color: transparent;*/
/*}*/